.feature-card-container {
  width: 540px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.feature-card-icon {
  width: 24px;
  height: 24px;
}
.feature-card-text {
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card-text1 {
  text-align: center;
}
.feature-card-root-class-name {
  align-self: center;
}
