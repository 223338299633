.home-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  height: 237px;
  display: flex;
  z-index: 100;
  position: relative;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 347px;
  height: 168px;
  align-self: center;
  object-fit: cover;
}
.home-menu {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.home-link:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text:hover {
  color: var(--dl-color-turquoise-default);
}
.home-link1 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.home-link1:hover {
  color: var(--dl-color-turquoise-default);
}
.home-link2 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.home-link2:hover {
  color: var(--dl-color-turquoise-default);
}
.home-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-left {
  right: -119px;
  width: 416px;
  bottom: -915px;
  height: 207px;
  position: absolute;
  object-fit: cover;
}
.home-left1 {
  left: -104px;
  width: 422px;
  bottom: -427px;
  height: 210px;
  position: absolute;
  object-fit: cover;
}
.home-hero {
  width: 1157px;
  height: 1029px;
  display: flex;
  min-height: 80vh;
  align-items: center;
  padding-top: 2px;
  padding-left: 1px;
  flex-direction: column;
  padding-bottom: 7px;
  justify-content: center;
}
.home-image1 {
  width: 757px;
  height: 448px;
  object-fit: cover;
  padding-top: 0px;
  padding-bottom: 25px;
}
.home-video {
  width: 958px;
  height: 494px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.home-banner {
  width: 100%;
  display: flex;
  padding: 48px;
  align-items: center;
  padding-top: 44px;
  flex-direction: column;
  justify-content: space-between;
}
.home-text02 {
  text-align: center;
  font-weight: 600;
  margin-bottom: 32px;
}
.home-features {
  width: 100%;
  height: 273px;
  display: flex;
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text03 {
  text-align: center;
}
.home-features1 {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text04 {
  align-self: center;
}
.home-text05 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 3;
}
.home-text06 {
  text-align: center;
}
.home-text14 {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 3;
}
.home-text15 {
  text-align: center;
}
.home-feature21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text27 {
  align-self: center;
}
.home-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-link3 {
  text-decoration: none;
}
.home-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
}
.home-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-image2 {
  width: 156px;
  height: 152px;
  object-fit: cover;
}
.home-container5 {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-start;
}
.home-link4 {
  color: rgb(0, 0, 0);
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link4:hover {
  color: var(--dl-color-turquoise-default);
}
.home-link5 {
  color: rgb(0, 0, 0);
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link5:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text29:hover {
  color: var(--dl-color-turquoise-default);
}
.home-link6 {
  color: rgb(0, 0, 0);
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link6:hover {
  color: var(--dl-color-turquoise-default);
}
.home-link7 {
  color: rgb(0, 0, 0);
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link7:hover {
  color: var(--dl-color-turquoise-default);
}
.home-link8 {
  color: #000000;
  transition: 0.3s;
  white-space: nowrap;
  text-decoration: none;
}
.home-link8:hover {
  color: var(--dl-color-turquoise-default);
}
.home-divider {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: #000000;
}
.home-container6 {
  width: 100%;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text31 {
  color: #000000;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
}
@media(max-width: 991px) {
  .home-text02 {
    text-align: center;
  }
  .home-container1 {
    align-items: flex-start;
    flex-direction: column;
  }
  .home-container2 {
    align-items: flex-start;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .home-hero {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-banner {
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-text03 {
    font-size: 40px;
  }
  .home-text04 {
    font-size: 40px;
  }
  .home-text27 {
    font-size: 40px;
  }
  .home-container5 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-container6 {
    flex-direction: column;
  }
  .home-text31 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-menu {
    display: flex;
  }
  .home-hero {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-banner {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-text03 {
    text-align: center;
  }
}
